import React from "react";
import {useTranslation} from 'react-i18next';
import { useQuery, useMutation } from 'react-apollo-hooks';

// Own components
import EditTicketForm from "../../forms/EditTicketForm";
import LoadingCircleView from "../../Views/LoadingCircleView";

// Local state
import useGlobal from "../../store";
import {UPDATE_TICKET_MUTATION} from "./Mutations";
import {APP_SEGMENTS_ALL,ALLOWED_TICKET_QUESTION_MODULES_ALL, USER_MOBILES_FEED} from './Queries';

const EditTicketContainer = (props) => {

  const [session, globalActions] = useGlobal(
    state => state.session,
    actions => actions
  );
  const { t, } = useTranslation('tickets');
  const { ticket } = props.location.state;
  const containModules = ticket.triggers.map(trigger => trigger._id).concat(ticket.finisher._id);

  const {
    data: dataUserMobile,
    error: errorUserMobile,
    loading: loadingUserMobile,
    refetch: refetchUserMobile
  } = useQuery(USER_MOBILES_FEED, {
    variables: {
      where: {
        app: session.appRef._id,
        archived: false
      }
    },
    fetchPolicy: "network-only"
  });
  const {
    data: datasegments,
    loading: loadingSegments
  } = useQuery(APP_SEGMENTS_ALL, {
    variables: {
      "where": {
        "app": session.user ? session.appRef._id : '',
        "archived": false
      }
    }
  });
  const {
    data: dataQEAllowed,
    loading: loadingQEAllowed
  } = useQuery(ALLOWED_TICKET_QUESTION_MODULES_ALL, {
    variables: {
      "where": {
        "app": session.user ? session.appRef._id : '',
        contain: containModules,
        "archived": false
      }
    }
  });
  const updateTicket = useMutation(UPDATE_TICKET_MUTATION);

  // Loading screen
  if (loadingQEAllowed || loadingSegments) {
    return(<LoadingCircleView />)
  }

  if ((!loadingSegments && !loadingQEAllowed && !loadingUserMobile) && (dataQEAllowed && datasegments)) {
    return (
      <EditTicketForm
        submitSuccess={(values) => {
          const validTicketSegmentAssignConfigs = values.ticketSegmentAssignConfigs.filter((assign) => {
            return assign.segment && assign.userMobile;
          }).map((assign) => {
            return {
              segmentId: assign.segment._id,
              userMobileId: assign.userMobile._id
            }
          });

          const variables = {
            data: {
              app: session.appRef._id,
              name: values.name,
              description: values.description,
              emails: {
                set: values.emails
              },
              triggers: {
                connect: values.triggers.map(trigger => ({_id: trigger._id}))
              },
              finisher: {
                connect: {
                  _id: values.finisher._id
                }
              },
              ticketSegmentAssignConfigs: validTicketSegmentAssignConfigs,
              whoResolves: values.whoResolves,
              segment: values.segment,
              archived: false,
              pdfReportConfig: values.pdfReportConfig,
              topOffender: values.topOffender,
              hasFolio: values.hasFolio,
              folio: ticket.folio,
              prefix: values.prefix,
              startAt: values.startAt,
              fieldsFilter: values.fieldsFilter
            },
            where: {
              _id: ticket._id
            }
          };

          const mutationResult = updateTicket({
            variables: variables
          });
          props.history.push('/tickets/all');

          // Add success notification
          globalActions.notification.openNotification('tr', {
            message: t('tickets.forms.update.success'),
            color: 'warning'
          });
        }}
        segments={loadingSegments ? [] : datasegments.segments}
        userMobiles={loadingUserMobile ? []: dataUserMobile.userMobiles}
        allowedQuestionModules={dataQEAllowed.questionEngineModulesTicketUnused}
        allowedQuestionModulesForFinish={dataQEAllowed.questionEngineModulesTicketFinishUnused}
        ticket={ticket}
      />
    );

  }

  return [];
};

export default EditTicketContainer;
