// import gql from "graphql-tag";
import { gql } from 'apollo-boost';

export const CREATE_TICKET_MUTATION = gql`
  mutation createTicket($data: TicketCreateInput!) {
    createTicket(data: $data) {
      app
      name
      triggers {
        _id
        module {
          _id
          name
        }
      }
      finisher {
        _id
        module {
          _id
          name
        }
      }
      whoResolves
      segment
      archived
      created
    }
  }
`;

export const UPDATE_TICKET_MUTATION = gql`
  mutation UpdateTicket(
    $data: TicketUpdateInput!
    $where: TicketWhereUniqueInput!
  ) {
    updateTicket(data: $data, where: $where) {
      _id
    }
  }
`;

export const CREATE_TICKET_REPORT_COMMENT = gql`
  mutation CreateTicketReportComment(
    $_id: ID!
    $text: String
    $userId: ID!
    $userType: UserTypeEnum!
  ) {
    createTicketReportComment(
      where: { _id: $_id }
      data: { text: $text, userId: $userId, userType: $userType }
    ) {
      _id
      text
      userRef {
        ... on UserGh {
          _id
          firstName
          lastName
          email
          __typename
        }
        ... on UserMobile {
          _id
          firstName
          lastName
          email
          __typename
        }
      }
      created
    }
  }
`;

export const DELETE_TICKET_REPORT_COMMENT = gql`
  mutation DeleteTicketReportComment($_id: ID!, $comment: ID!) {
    deleteTicketReportComment(where: { _id: $_id, comment: $comment }) {
      _id
      text
    }
  }
`;

export const UPDATE_TICKET_REPORT_MUTATION = gql`
  # Write your query or mutation here
  mutation UpdateTicketReport(
    $_id: ID!
    $app: ID!
    $status: TicketReportStatus!
    $user: ID
    $ticketReportLog: TicketReportLogInput!
  ) {
    updateTicketReport(
      where: { _id: $_id }
      data: {
        app: $app
        status: $status
        user: $user
        ticketReportLog: $ticketReportLog
      }
    ) {
      _id
      user
      comments {
        _id
        text
      }
      userRef {
        _id
        firstName
        lastName
        email
      }
      status
      questionEngineAnswerTrigger {
        _id
        module {
          _id
          name
        }
        userId
        userRef {
          _id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const GENERATE_TICKET_REPORT = gql`
  mutation createTicketReportResult($data: TicketReportResultInput!){
    createTicketReportResult(data: $data)
  }
`;

export const GENERATE_TICKET_REPORT_XSL = gql`
  mutation generateTicketReportXLS($data: ChecklistRepordInput!){
    generateTicketReportXLS(data: $data)
  }
`;


export const CREATE_UPDATE_TICKET_DELAY_ALERT_CONFIG = gql`
  mutation createUpdateTicketsDelayAlertConfig($data: TicketConfigDelayAlertInput, $where: TicketConfigDelayAlertWhereInput) {
    createupdateticketsdelayalertconfig(where: $where, data: $data ) {
       	_id
				app
				ticket
				startStatus
				endStatus
				config {
					step
					timeDelay
					addUserAsigned
					mails
				}
				sendMailOnfinished
				archived
				finished
				createdAt
				updatedAt
    }
}
`;

export const UPDATE_TICKET_USERS_ADMINS = gql`
  mutation updateTicketUsersAdmins ($data: TicketUsersAdminsinput) {
    updateTicketUsersAdmins(data: $data) {
      _id
      name
      usersAdmins {
        enableSecurity
        users {
          _id
          fullName
          email
        }
      }
    }
  }
`;