import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

import Button from "components/Button";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Timeline from "../Timeline/Timeline";
import {Collapse} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CardHeaderSimple from "components/Header/CardHeaderSimple"

function VisitsHistory({classes, visitAnswers}){
  const [isOpen, setIsOpen] = React.useState(false);
  const data = visitAnswers.sort((a, b) => {
    return a.checkOutDate < b.checkOutDate ? 1 : a.checkOutDate > b.checkOutDate ?  -1 : 0;
  }).slice(0, 15).map((item) => {
    return ({
      badgeColor: "success",
      badgeIcon: FiberManualRecordIcon,
      body: (
        <React.Fragment>
          <div>
            {item.checkOutDate.format("DD/MM/YYYY")}
          </div>
          <div>
            {`${item.userRef.firstName} ${item.userRef.lastName}`} 
          </div>
        </React.Fragment>
      ),      
    });
  });

  return (
    <Card>
      {/* <CardHeader color="info" text>
        <GridContainer>
          <GridItem >
            <CardText color="info">
              <h4 className={classes.cardTitle}>Ultimas visitas cerradas</h4>
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader> */}
      <CardHeaderSimple title={"Ultimas visitas cerradas"} />  
      <CardBody> 
        <GridContainer>
          <GridItem xs={12}>
            <Button
              className={classes.buttonWithIcon}
              color={'info'}
              fullWidth
              size="sm"
              onClick={() => {setIsOpen(prevState => !prevState)}} 
            >
              Mostrar historial de visitas {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Timeline stories={data} simple={true} counter={false}/>
            </Collapse>  
          </GridItem>
        </GridContainer>    
      </CardBody>
    </Card>
  );
}

VisitsHistory.propTypes = {
  visitAnswers: PropTypes.array.isRequired
};

const styles = theme => ({
  buttonWithIcon: {
    display: "flex",
    justifyContent: "space-between",
  },
  reportButton: {
    margin: "0px 10px"
  }
});

export default withStyles(styles)(VisitsHistory);