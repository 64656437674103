import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

import Button from "components/Button";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import { CircularProgress } from '@material-ui/core';
import CardHeaderSimple from "components/Header/CardHeaderSimple"

const {GH_FRONT_URL} = window._env_;

function ActiveVisits({classes, activeVisitAnswer, visitID}){
  const { loadingActiveVisitanswers, dataOpenVisitanswer } = activeVisitAnswer;
  
  let count = null;
  if (!loadingActiveVisitanswers) {
    if (dataOpenVisitanswer) {
      const { visitAnswerConnection } = dataOpenVisitanswer;
      const { aggregate } = visitAnswerConnection;
      count = aggregate.count;
    }
    
  }
  return (
    <Card>
      {/* <CardHeader color="info" text>
        <GridContainer>
          <GridItem >
            <CardText color="info">
              <h4 className={classes.cardTitle}>Visitas Activas</h4>
            </CardText>
          </GridItem>
          <GridItem >
            <CardText color="info">
              { loadingActiveVisitanswers ? 
                <h4 className={classes.counter}>...</h4>
               :
                <h4 className={classes.counter}>{count}</h4>
              }
              
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader> */}

      <CardHeaderSimple title={"Visitas Activas"} cardRight={{ value: count, loading: "..." }} /> 
      <CardBody> 
        {
          loadingActiveVisitanswers ? 
            <CircularProgress className={classes.progress} />
          :
          <GridContainer>
            <GridItem xs={12}>
              <a 
                href={GH_FRONT_URL+"/#/app/visits/"+visitID+"/report?actives=true"} 
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  size="sm"
                  color="info"
                  fullWidth
                >
                  Ver reporte completo
                </Button>
              </a>
            </GridItem>
          </GridContainer>   
        }
                
      </CardBody>
    </Card>
  );
}

ActiveVisits.propTypes = {
  activeVisitAnswer: PropTypes.object.isRequired,
  visitID: PropTypes.string.isRequired
};

const styles = theme => ({
  countContainer:{
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    textAlign: "center"
  },
  counter: {
    padding: "0px 12px"
  },
  progress:{
    marginLeft: '50%',
    marginRight: '50%'
  },
});

export default withStyles(styles)(ActiveVisits);