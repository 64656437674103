import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useQuery} from "react-apollo-hooks";
import { Field, Form, withFormik } from 'formik';
import * as yup from 'yup';
import Datetime from "react-datetime";
import styled from 'styled-components';

// own components
import FieldAutosuggestMultiAsync from "../fields/FieldAutosuggestMultiAsync";

// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import Search from '@material-ui/icons/Search';

// custom components
import ButtonIcon from '../../components/styled/ButtonIcon';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

//Utils
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import {APPROVAL_ITEM_STATUS, APPROVAL_LOG_STATUS, APPROVAL_LOG_TYPE} from "../../constants";
import {USERS_GH_SEARCH} from "../gql/Queries";
import useGlobal from '../../store';

const DivMarginBottom = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

/*
 * Validation Schema for TicketReportMaintenanceFilterForm
 * */
const validationSchema = yup.object().shape({
  status: yup.array().of(yup.string()),
  startDateCreated: yup.date().required(),
  endDateCreated: yup.date().required()
});

const AdminApprovalItemsFilterForm = ({classes, ...props}) => {
  // Main hooks
  const { t } = useTranslation(['visits']);
  const [ session, ] = useGlobal(
    state => state.session,
    actions => actions
  );

  // Formik bag
  const { setFieldValue, values, submitForm, visits } = props;

  const [currentUserGHSearch, setCurrentUserGHSearch] = useState({
    app_access: session.user ? session.appRef._id : '',
    email_contains: ''
  });
  // Query for search
  const {data: dataUsersGH, loading: loadingUsersGH} = useQuery(USERS_GH_SEARCH, {
    variables: {
      where: currentUserGHSearch
    }
  });

  return (
    <Form>
      <GridContainer>
        <GridItem xs={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel className={classes.selectLabel}>
              {t("approvalItems.forms.adminFilter.approvalItemStatus", {ns: 'approvalItems'})}
            </InputLabel>
            <Field
              name="approvalItemStatus"
              render={({ field }) => {
                return (
                  <Select
                    multiple
                    value={field.value}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    inputProps={{ ...field }}
                  >
                    {Object.keys(APPROVAL_ITEM_STATUS).map(status => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={APPROVAL_ITEM_STATUS[status]}
                        key={APPROVAL_ITEM_STATUS[status]}
                      >
                        {t(`approvalItems.model.ApprovalItemStatus.${APPROVAL_ITEM_STATUS[status]}`,
                          {ns: 'approvalItems'})}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <InputLabel className={classes.label}>
            {t("approvalItems.forms.adminFilter.startDateCreated", {ns: 'approvalItems'})}
          </InputLabel>
          <Datetime
            name={'startDateCreated'}
            timeFormat={false}
            defaultValue={values.startDateCreated}
            onChange={e => {
              if (e === '') {
                setFieldValue('startDateCreated', e);
              }else{
                setFieldValue('startDateCreated', e._d);
              }
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <InputLabel className={classes.label}>
            {t("approvalItems.forms.adminFilter.endDateCreated", {ns: 'approvalItems'})}
          </InputLabel>
          <Datetime
            name={'endDateCreated'}
            timeFormat={false}
            defaultValue={values.endDateCreated}
            onChange={e => {
              if (e === '') {
                setFieldValue('endDateCreated', e);
              }else{
                setFieldValue('endDateCreated', e._d);
              }
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <ButtonIcon
            icon={Search}
            label={'Busqueda'}
            color={'primary'}
            block={true}
            onClick={() => {
              submitForm();
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel className={classes.selectLabel}>
              {t("approvalItems.forms.adminFilter.approvalLogStatus", {ns: 'approvalItems'})}
            </InputLabel>
            <Field
              name="approvalLogStatus"
              render={({ field }) => {
                return (
                  <Select
                    multiple
                    value={field.value}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    inputProps={{ ...field }}
                  >
                    {Object.keys(APPROVAL_LOG_STATUS).map(status => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={APPROVAL_LOG_STATUS[status]}
                        key={APPROVAL_LOG_STATUS[status]}
                      >
                        {t(`approvalItems.model.ApprovalLogStatus.${APPROVAL_LOG_STATUS[status]}`,
                          {ns: 'approvalItems'})}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel className={classes.selectLabel}>
              {t("approvalItems.forms.adminFilter.approvalLogType", {ns: 'approvalItems'})}
            </InputLabel>
            <Field
              name="approvalLogType"
              render={({ field }) => {
                return (
                  <Select
                    multiple
                    value={field.value}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    inputProps={{ ...field }}
                  >
                    {Object.keys(APPROVAL_LOG_TYPE).map(status => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={APPROVAL_LOG_TYPE[status]}
                        key={APPROVAL_LOG_TYPE[status]}
                      >
                        {t(`approvalItems.model.ApprovalLogType.${APPROVAL_LOG_TYPE[status]}`,
                          {ns: 'approvalItems'})}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel className={classes.selectLabel}>
              {t("approvalItems.forms.adminFilter.visits", {ns: 'approvalItems'})}
            </InputLabel>
            <Field
              name="visits"
              render={({ field }) => {
                return (
                  <Select
                    multiple
                    value={field.value}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    inputProps={{ ...field }}
                  >
                    {
                      !!visits ? visits.map((visit) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={visit._id}
                            key={visit._id}
                          >
                            {visit.name}
                          </MenuItem>
                        );
                      }) : []
                    }
                  </Select>
                );
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <FieldAutosuggestMultiAsync
            name={'users'}
            suggestionList={
              loadingUsersGH ?
                [] :
                dataUsersGH.userGHs || []
            }
            getSuggestionId={suggestion => suggestion._id}
            getSuggestionName={suggestion => suggestion.email}
            getSuggestionById={id => {
              if (!loadingUsersGH) {
                return dataUsersGH.userGHs.find(user => user._id === id);
              }
              return {
                value: id
              };
            }}
            placeholder={'approvalItems.forms.adminFilter.users'}
            tableTitle={'common.views.selected'}
            noResultsLabel={'common.views.noSelected'}
            loadSuggestions={(value) => {
              setCurrentUserGHSearch({
                ...currentUserGHSearch,
                "email_contains": value
              })
            }}
          />
        </GridItem>
      </GridContainer>
    </Form>
  );
};

AdminApprovalItemsFilterForm.propTypes = {
  submitSuccess: PropTypes.func.isRequired
};

const AdminApprovalItemsFilterFormWF = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: true,
  mapPropsToValues: ({initialValues}) => {
    return {
      approvalItemStatus: initialValues.approvalItemStatus,
      approvalLogStatus: initialValues.approvalLogStatus,
      approvalLogType: initialValues.approvalLogType,
      visits: initialValues.visits,
      users: initialValues.users,
      startDateCreated: initialValues.created_gte,
      endDateCreated: initialValues.created_lte
    };
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  }
})(AdminApprovalItemsFilterForm);

export default withStyles(extendedFormsStyle)(AdminApprovalItemsFilterFormWF);
