import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

import Button from "components/Button";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import { CircularProgress } from '@material-ui/core';
import CardHeaderSimple from "components/Header/CardHeaderSimple"
const {GH_FRONT_URL} = window._env_;


function ClosedVisits({classes, count, visitID}){
  return (
    <Card>
      {/* <CardHeader color="info" text>
        <GridContainer>
          <GridItem>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Visitas cerradas</h4>
            </CardText>
          </GridItem>
          <GridItem>
            <CardText color="info">
              {
                count === null ? <h4 className={classes.counter}>...</h4>
                :<h4 className={classes.counter}>{count}</h4>
              }
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader> */}

      <CardHeaderSimple title={"Visitas Cerradas"} cardRight={{ value: count, loading: "..." }} /> 
      <CardBody> 
        {
          count === null ? <CircularProgress className={classes.progress} />
          : 
          <GridContainer>
            <GridItem xs={12}>
              <a               
                href={GH_FRONT_URL+"/#/app/visits/"+visitID+"/report?actives=false"} 
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button
                  size="sm"
                  color="info"
                  fullWidth
                >
                  Ver reporte completo
                </Button>
              </a>
              <a 
                href={GH_FRONT_URL+"/#/app/visits/"+visitID+"/report/checklist"} 
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button
                  size="sm"
                  color="info"
                  fullWidth
                >
                  Ver reporte de calificables
                </Button>
              </a>
            </GridItem>
          </GridContainer>
        }
                   
      </CardBody>
    </Card>
  );
}

ClosedVisits.propTypes = {
  visitID: PropTypes.string.isRequired
};

const styles = theme => ({
  counter: {
    padding: "0px 12px"
  },
  progress:{
    marginLeft: '50%',
    marginRight: '50%'
  },
});

export default withStyles(styles)(ClosedVisits);