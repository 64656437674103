import React, { useState } from "react";
import ReactTable from "react-table";
import DivCenter from "../../components/styled-components/DivCenter";
import { Typography, Checkbox, makeStyles, Select, MenuItem } from "@material-ui/core";
import TagsInput from "react-tagsinput";

const ElementsSelectTable = ({
  data,
  setFieldValue,
  elementsIds,
  disabled
}) => {
  const classes = useStyles();
  const [tagSelected, setTagSelected] = useState([])

  const containsFilter = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
      : true;
  };

  const uniqueTags = [...new Set(data.map(element => element.tags).flat())];
  
  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: (
            <DivCenter className={classes.headerTitle}>Elementos</DivCenter>
          ),
          accessor: "name",
          filterable: true,
          sortable: false,
          filterMethod: containsFilter,
          style: { 
            textAlign: "left",
            whiteSpace: "unset",
            fontSize: "14px"

          },
          width: 200,
          Cell: ({ original }) => {
            return (
              <Typography className={classes.contentText}>
                {original.name}
              </Typography>
            );
          },
        },
        {
          Header: (
            <DivCenter className={classes.headerTitle}>
              <Checkbox
                id={`visit-check`}
                color="default"
                disabled={disabled}
                classes={{
                  root: classes.radio,
                  checked: classes.checked,
                }}
                checked={elementsIds.length === data.length}
                onChange={() => {
                  //let elements = [...elementsIds];
                  if (elementsIds.length === data.length) {
                    setFieldValue("elementsIds", []);
                  } else {
                    const allElements = data.map((element) => element._id);
                    setFieldValue("elementsIds", [...allElements]);
                  }

                  setTagSelected([]);
                }}
              />
            </DivCenter>
          ),
          accessor: "select",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          width: 50,
          Cell: ({ original }) => {
            return (
              <Checkbox
                id={`visit-check`}
                color="default"
                disabled={disabled}
                classes={{
                  root: classes.radio,
                  checked: classes.checked,
                }}
                checked={elementsIds.indexOf(original._id) !== -1}
                onChange={() => {
                  let elements = [...elementsIds];
                  if (elementsIds.indexOf(original._id) === -1) {
                    elements.push(original._id);
                  } else {
                    elements.splice(elements.indexOf(original._id), 1);
                  }
                  setFieldValue("elementsIds", [...elements]);
                }}
              />
            );
          },
        },
        {
          Header: '',
          accessor: "tag",
          filterable: true,
          sortable: false,
          style: {  
            textAlign: "left",
            whiteSpace: "unset",
          },
          width: 150,
          Cell: ({ original }) => {
            return (
              <TagsInput 
                value={original.tags ? original.tags : []}
                tagProps={{ className: "react-tagsinput-tag info" }}
                inputProps={{
                  placeholder: "",
                }}
                disabled={true}
              />
            );
          },
          Filter: () => (
            !disabled ? <Select
              multiple
              style={{ width: "100%" }}
              value={tagSelected}
              onChange={(event) => {
                const { target: { value }} = event;
                setTagSelected(value);

                const elements = data.filter(element =>
                  element.tags.some(tag => value.includes(tag))
                ).map(element => element._id);

                setFieldValue("elementsIds", [...elements]);
              }}
            >
              {
                uniqueTags.map(tag => (
                  <MenuItem value={tag} key={tag}>
                    {tag}
                  </MenuItem>
                ))
              }
            </Select> : []
          )
        },
      ]}
      defaultPageSize={data.length}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      resizable={false}
      noDataText={"No data"}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    fontSize: "20px",
    whiteSpace: "break-spaces",
    fontWeight: "500",
  },
  contentText: {
    fontSize: 18,
  },
  textColor: {
    color: "#000",
  },
  radio: {
    "&$checked": {
      color: "#6ab3d4",
    },
  },
  checked: {},
}));

export default ElementsSelectTable;
