import React from 'react';

import withStyles from "@material-ui/core/styles/withStyles";
// import ButtonMui from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowRight';
import Button from "components/Button";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import ComingSoon from '../../../components/ComingSoon/ComingSoon';
import history from '../../../utils/history';
import useGlobal from '../../../store';
import CardHeaderSimple from "components/Header/CardHeaderSimple"

function SpecialReports({classes}){
  const [ filters ] = useGlobal(
    state => state.filters,
    actions => actions
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleNavigation = (reportURL) => {
    history.push(`/visits/${filters.selectedVisitID.value}/${reportURL}`);
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Card>
      {/* <CardHeader color="info" text>
        <GridContainer>
          <GridItem>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Reportes Especiales</h4>
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader> */}
      <CardHeaderSimple title={"Reportes Especiales"} />
      <CardBody>    
     
        <div style={{ "margin-bottom":"10px"}}>
          <Button
            size="md"
            color="beepquest"
            fullWidth
            button={false}
            onClick={() => handleNavigation("opportunityAreas")}
          >
            AREAS DE OPORTUNIDAD
          </Button>
        </div>
        <div style={{ "margin-bottom":"10px"}}>
        <Button
          button={false}
          size="md"
          color="beepquest"
          fullWidth
          onClick={() => handleNavigation("histogram")}
        >          
            Distribución de calificaciones                    
        </Button>
        </div>
        <div style={{ "margin-bottom":"10px"}}>
        <Button
          button={false}
          size="md"
          color="beepquest"
          fullWidth
          onClick={() => handleNavigation("adminData")}
        >
          DATOS ADMINISTRATIVOS
        </Button>
        </div>
        <div style={{ "margin-bottom":"10px"}}>
        <Button
          button={false}
          size="md"
          color="beepquest"
          fullWidth
          onClick={() => handleNavigation("galleryVisor")}
        >
          VISOR DE GALERIAS
        </Button>
        </div>
        <div style={{ "margin-bottom":"10px"}}>
        <Button
          size="md"
          color="beepquest"
          button={false}
          fullWidth
          onClick={() => handleNavigation("tablesVisor")}
        >
          VISOR DE TABLAS
        </Button>
        </div>
        <div style={{ "margin-bottom":"10px"}}>
        <Button
          size="md"
          color="beepquest"
          button={false}
          fullWidth
          onClick={() => handleNavigation("visitsVisor")}
        >
          VISOR DE VISITAS
        </Button>
        </div>
        
        <ComingSoon
          anchorEl={anchorEl}

          id={id}
          handleClose={handleClose}
          open={open}
        /> 
      </CardBody>
    </Card>);
}

SpecialReports.propTypes = {
};

const styles = theme => ({
});

export default withStyles(styles)(SpecialReports);