import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.jsx";
import DivCenter from "../../components/styled-components/DivCenter";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import GetAppIcon from "@material-ui/icons/GetApp";
import BlockIcon from "@material-ui/icons/Block";
import { makeStyles, Menu, MenuItem, Select } from "@material-ui/core";

const LastReportsGeneratedTable = ({
  lastReports,
  type,
  name,
  action,
  user,
  email,
  status,
  created,
  completedDate,
  url,
  nameAction,
  pageSize,
  paginationAction,
  reportsFilter,
}) => {
  const { t } = useTranslation("reports");
  const classes = useStyles();
  const options = [
    {
      label: "M",
      value: 1,
    },
    {
      label: "P",
      value: 2,
    },
    {
      label: "A",
      value: 3,
    },
  ];

  const renderDowloadButton = (url, urls) => {
    if(urls.length){
      return (
        <DivCenter>
          <Select
            disableUnderline
            IconComponent={GetAppIcon}
            renderValue={() => {
              return "";
            }}
            classes={{
              icon: classes.icon,
              iconOpen: classes.iconOpen,
              select: classes.selectSelect,
            }}
          >
            {urls.map((value, index) => {
              return (
                <a className={classes.linkColor} href={value} target="_blank">
                  <MenuItem key={index} value={value}>{`Reporte ${
                    index + 1
                  }`}</MenuItem>
                </a>
              );
            })}
          </Select>
        </DivCenter>
      );
    }else{
      return (
        <a className={classes.linkColor} href={url} target="_blank">
          <DivCenter>
            <GetAppIcon />
          </DivCenter>
        </a>
      );
    }
  };

  return (
    <ReactTable
      data={lastReports}
      columns={[
        {
          Header: <DivCenter>Descargar</DivCenter>,
          accessor: "url",
          show: url,
          Cell: (d) => {
            const {status, url, urls} = d.original;
            
            return status !== 1 ? (
              <DivCenter>
                <BlockIcon />
              </DivCenter>
            ) : renderDowloadButton(url, urls)
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "left", whiteSpace: "unset" },
          maxWidth: 100,
        },
        {
          Header: <DivCenter>Tipo</DivCenter>,
          accessor: "type",
          show: type,
          Cell: (d) => {
            switch (d.original.type) {
              case 1:
                return (
                  <Tooltip title="Manual">
                    <Button size="sm" round color="beepquest">
                      M
                    </Button>
                  </Tooltip>
                );
              case 2:
                return (
                  <Tooltip title="Progamado">
                    <Button size="sm" round color="beepquest">
                      P
                    </Button>
                  </Tooltip>
                );
              case 3:
                return (
                  <Tooltip title="Automatico">
                    <Button size="sm" round color="beepquest">
                      A
                    </Button>
                  </Tooltip>
                );
              default:
                return "";
            }
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10,
          filterMethod: (filter, row) => {
            /*  let type = parseInt(event.target.value);
            reportsFilter.skip = 0;
            if (type) {
              reportsFilter.type = type;
            }else{
              delete reportsFilter.type;
            }


            paginationAction(reportsFilter); */
          },
          Filter: ({ filter, onChange }) => {
            return (
              <select
                onChange={(event) => onChange(event.target.value)}
                multiple={false}
              >
                <option value="0">Todos</option>
                <option value="1">M</option>
                <option value="2">P</option>
                <option value="3">A</option>
              </select>
            );
          },
        },
        {
          Header: <DivCenter>Nombre</DivCenter>,
          accessor: "nameAction",
          show: nameAction,
          Cell: (d) => {
            return `${t(d.original.action)} - ${
              d.original.name ? d.original.name : ""
            }`;
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "left", whiteSpace: "unset" },
          minWidth: 50,
        },
        {
          Header: <DivCenter>Acción</DivCenter>,
          accessor: "action",
          show: action,
          Cell: (d) => {
            return `${t(d.original.action)}`;
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "left", whiteSpace: "unset" },
          minWidth: 30,
        },
        {
          Header: <DivCenter>Nombre</DivCenter>,
          accessor: "name",
          show: name,
          Cell: (d) => {
            if (d.original.name) {
              return d.original.name ? d.original.name : "";
            } else {
              if (d.original.type == 3) {
                return "Reporte enviado automáticamente";
              }

              return "Reporte ejecutado manualmente";
            }
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "left", whiteSpace: "unset" },
          minWidth: 50,
          maxWidth: 200,
        },
        {
          Header: <DivCenter>Usuario</DivCenter>,
          accessor: "user",
          Cell: (d) => {
            if (d.original.type === 1 || d.original.user) {
              if (d.original.user?.email) {
                return d.original.user.email;
              }
            }
            if (d.original.status !== 2) {
              return "Sistema";
            }

            return "";
          },
          show: user,
          filterable: false,
          sortable: false,
          style: { textAlign: "left" },
          minWidth: 100,
          maxWidth: 200,
        },
        {
          Header: <DivCenter>Email</DivCenter>,
          accessor: "user",
          Cell: (d) => {
            if (d.original.type === 2) {
              if (d.original.query) {
                let programableReport = d.original.query.programableReport;
                return programableReport.users
                  .map((user) => user.email)
                  .join(",");
              } else {
                return "";
              }
            } else {
              if (d.original.type === 3) {
                return "Reporte enviado automáticamente";
              }
              if (
                d.original.type === 1 &&
                d.original.query &&
                d.original.query.emails
              ) {
                return d.original.query.emails.join(",");
              } else {
                return "Reporte ejecutado manualmente";
              }
            }

            return "";
          },
          show: email,
          filterable: false,
          sortable: false,
          style: { textAlign: "left", whiteSpace: "unset" },
          minWidth: 100,
          maxWidth: 250,
        },
        {
          Header: <DivCenter>Estatus de reporte</DivCenter>,
          accessor: "status",
          Cell: (d) => {
            switch (d.original.status) {
              case 0:
                return "Procesando";
              case 1:
                return "Creado";
              case 2:
                return "Error";
              default:
                return "";
            }
          },
          show: status,
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 30,
        },
        {
          id: "created",
          Header: <DivCenter>Fecha de solicitud</DivCenter>,
          show: created,
          accessor: (d) => {
            return moment(d.created).local().format("DD-MM-YY : hh:mm:ss A");
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 30,
        },
        {
          id: "completionDate",
          Header: <DivCenter>Fecha de creación</DivCenter>,
          show: completedDate,
          accessor: (d) => {
            if (d.completionDate) {
              return moment(d.completionDate)
                .local()
                .format("DD-MM-YY : hh:mm:ss A");
            } else {
              return "";
            }
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 30,
        },
      ]}
      defaultPageSize={pageSize}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

LastReportsGeneratedTable.propTypes = {
  lastReports: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  icon:{
    left:0,
    marginLeft: '8px',
    fill: '#000'
  },
  iconOpen:{
    transform:'none'
  },
  selectSelect:{
    paddingLeft: 0
  },
  linkColor: {
    color: "inherit",
  }
}));
export default LastReportsGeneratedTable;
