import React from 'react';
import PropTypes from 'prop-types';
import useGlobal from '../../../store';
import history from "utils/history";

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import InformationCheckboxList from "components/InformationCheckbox/InformationCheckboxList";
import CardHeaderSimple from "components/Header/CardHeaderSimple"
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
function GeneralInformation({classes, categories, keyfields, stateCategory, stateKeyfields, 
  globalCategory, setStateCategory, setStateKeyfields, setGlobalCategory, hasGlobal, operation}){
  const [ filters ] = useGlobal(
    state => state.filters,
    actions => actions
  );

  const navigateKeyfield = React.useCallback((keykfield) => {
    history.push(`/visits/${keykfield.id}/keyfield/${filters.selectedVisitID.value}/dashboard`);
  },[filters]);

  const navigateCategory = React.useCallback((category) => {
    history.push(`/visits/${category.id}/category/${filters.selectedVisitID.value}/dashboard`);
  },[filters]);

  return(
    <Card>
      {/* <CardHeader color="info" text>
        <GridContainer>
          <GridItem xs={6}>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Información General Checklist</h4>
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader> */}
      <CardHeaderSimple titleStyle={{"color":"#000", "float": "left",  "margin-left":"15px","margin-top":"15px"}} cardLeft={{ value: (<AssignmentTurnedInIcon size={5} />) }} title={"Información General Checklist"} />
      <CardBody>            
        <GridContainer>
          <GridItem lg={6} sm={6} xs={12}>
            <h4 className={classes.cardTitle}>Campos Clave</h4>
            <InformationCheckboxList
              items={keyfields}
              config={{
                hasGlobal: false,
                unique: false,
                // TODO: Add this callback to get the configuration to calculate the displayed values
                setSelected: () => {},
                onViewClick: navigateKeyfield, 
              }}
              states={stateKeyfields}
              setStates={setStateKeyfields}
              global={globalCategory}
              setGlobal={setGlobalCategory}
              operation={operation}
            />
          </GridItem>
          <GridItem  lg={6} sm={6} xs={12}>
            <h4 className={classes.cardTitle}>Categorías de Supervisión</h4>
            <InformationCheckboxList
              items={categories}
              config={{
                hasGlobal: hasGlobal,
                unique: true,
                // TODO: Add this callback to get the configuration to calculate the displayed values
                setSelected: () => {},
                onViewClick: navigateCategory,
              }}
              states={stateCategory}
              setStates={setStateCategory}
              global={globalCategory}
              setGlobal={setGlobalCategory}
              operation={operation}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

GeneralInformation.propTypes = {
  categories: PropTypes.array.isRequired,
  keyfields: PropTypes.array.isRequired,
  hasGlobal: PropTypes.bool.isRequired,
  stateCategory: PropTypes.array.isRequired,
  stateKeyfields: PropTypes.array.isRequired,
  globalCategory: PropTypes.bool.isRequired,
  operation: PropTypes.object,
  setStateCategory: PropTypes.func.isRequired,
  setStateKeyfields: PropTypes.func.isRequired,
  setGlobalCategory: PropTypes.func.isRequired
};

const styles = theme => ({
});

export default withStyles(styles)(GeneralInformation);