import React from "react";
import Datetime from "react-datetime";
// used for making the prop types of this component
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardText from "components/Card/CardText.jsx";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardHeaderSimple from "components/Header/CardHeaderSimple"

function DateRangeInput({classes, startDate, endDate, setStartDateRange, setEndDateRange}){
  return (
    <Card className={classes.cardLessBorder}>
      {/* <CardHeader color="info" text>
          <GridContainer>
            <GridItem>
              <CardText color="info">
                <h4>Rango de Fechas</h4>
              </CardText>
            </GridItem>
          </GridContainer>
        </CardHeader> */}
      <CardHeaderSimple title={"Rango de Fechas"} />  
      <CardBody>
        <Datetime
          name={'startDateRange'}
          timeFormat={false}
          defaultValue={startDate}
          onChange={e => {
            if (!!e){
              setStartDateRange(e);
            }
          }}
        />
        <Datetime
          name={'endDateRange'}
          timeFormat={false}
          defaultValue={endDate}
          onChange={e => {
            if (!!e){
              setEndDateRange(e);
            }
          }}
        />
      </CardBody>
    </Card>
  );  
}

DateRangeInput.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.number,
  setStartDateRange: PropTypes.func,
  setEndDateRange: PropTypes.func,
};

const styles = theme => ({
  cardLessBorder: {
    marginBottom: "0px"
  }
});

export default withStyles(styles)(DateRangeInput);