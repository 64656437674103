import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

//core components
import HeaderLinks from "./HeaderLinks";
import Button from "components/Button";

import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

const Header = ({ ...props }) => {
  const { t } = useTranslation("routeTitles");
  const makeBrand = () => {
    let name;
    props.routes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === props.location.pathname) {
            name = t(`routeTitles.${prop.name}`);
          }
          return null;
        });
      }
      if (prop.path === props.location.pathname) {
        name = prop.name;
      }
      return null;
    });
    if (name) {
      return name;
    } else {
      return "";
    }
  };

  const { classes, color } = props;

  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });

  const sidebarMinimize = classes.sidebarMinimize;

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                id={"sidebar-menu"}
                justIcon
                round
                // color="info"
                onClick={props.sidebarMinimize}
                style={{ "background-color":"#006aa3"}}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                // color="info"
                onClick={props.sidebarMinimize}
                style={{ "background-color":"#006aa3"}}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <h4>{makeBrand()}</h4>
        </div>
        <Hidden smDown implementation="css">
          <HeaderLinks history={props.history} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
};

export default withStyles(headerStyle)(Header);
