import React, { useState } from "react";
import { Field, Form, withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  makeStyles,
  InputLabel,
  IconButton,
  Button,
  Switch,
} from "@material-ui/core";
import { getErrorLabel, hasError } from "../../utils/forms/inputs";
import Datetime from "react-datetime";
import moment from "moment";
import ElementsSelectTable from "../../tables/ProcessRelationship/ElementsSelectTable";
import ImageUploading from "react-images-uploading";
import Cancel from "@material-ui/icons/Cancel";
import ButtonIcon from "components/styled/ButtonIcon";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Select from "react-select";
import history from "../../utils/history";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CancelIcon from '@material-ui/icons/Cancel';
import CachedIcon from '@material-ui/icons/Cached';

const RequisitionSchema = yup.object().shape({
  name: yup.string().required(),
  areaName: yup.string().required(),
  expirationDate: yup.string().required(),
  description: yup.string(),
  elementsIds: yup.array().required(),
  solutionType: yup.string().required(),
  solutionModuleId: yup.string(),
  questionEngineName: yup.string(),
  images: yup.array(),
  expiredSwitch: yup.boolean(),
});

const RequisitionForm = (props) => {
  const {
    errors,
    touched,
    values,
    setFieldValue,
    submitForm,
    isValid,
    elemets,
    questionEngineModules,
    disabled
  } = props;
  const [questionEngineType, setQuestionEngineType] = useState(values.solutionType ? 1 : null);

  const classes = useStyles();

  const questionEngineTypeSolution = () => {
    switch (questionEngineType) {
      case 1:
        return (
          <FormControl fullWidth={true}>
            <h4>Selecciona un motor de preguntas para resolver la requisición</h4>
            <Select
              value={questionEngineModules
                .map((module) => ({
                  value: module._id,
                  label: module.module.name,
                }))
                .find((option) => values.solutionModuleId === option.value)}
              onChange={(item) => {
                setFieldValue("solutionModuleId", item.value);
              }}
              options={questionEngineModules.map((module) => ({
                value: module._id,
                label: module.module.name,
              }))}
            />
          </FormControl>
        );

      case 2:
        return (
          <Field
            name="questionEngineName"
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="questionEngineName"
                  label={"Nombre del motor de preguntas para resolución"}
                  success={
                    !hasError(errors, "questionEngineName") &&
                    touched.questionEngineName
                  }
                  error={
                    hasError(errors, "questionEngineName") &&
                    touched.questionEngineName
                  }
                  margin="normal"
                  type={"text"}
                  fullWidth
                />
              );
            }}
          />
        );
    }
  };

  const changedSwitchEnable = (event) => {
    setFieldValue("expiredSwitch", event.target.checked);
  }

  console.log({
    elemets
  })

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={6}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Field
                    name="name"
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="name"
                          label={"Nombre"}
                          success={!hasError(errors, "name") && touched.name}
                          error={hasError(errors, "name") && touched.name}
                          margin="normal"
                          type={"text"}
                          fullWidth
                        />
                      );
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="description"
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="description"
                          label={"Descripción"}
                          type={"text"}
                          fullWidth
                          success={
                            !hasError(errors, "description") &&
                            touched.description
                          }
                          error={hasError(errors, "description")}
                        />
                      );
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="areaName"
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="areaName"
                          label={"Area que lo emite"}
                          success={
                            !hasError(errors, "areaName") && touched.areaName
                          }
                          error={
                            hasError(errors, "areaName") && touched.areaName
                          }
                          margin="normal"
                          type={"text"}
                          fullWidth
                        />
                      );
                    }}
                  />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: "30px" }}>
                  <InputLabel style={{ fontsize: "20px" }}>
                    Fecha expiración
                  </InputLabel>
                  <Datetime
                    name={"expirationDate"}
                    timeFormat={false}
                    defaultValue={new Date(values.expirationDate)}
                    onChange={(e) => {
                      if (e === "") {
                        setFieldValue("expirationDate", e);
                      } else {
                        setFieldValue("expirationDate", e._d);
                      }
                    }}
                    className={{ fontsize: "40px" }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    className={classes.marginTop}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.expiredSwitch}
                          onChange={changedSwitchEnable}
                          name="expiredSwitch"
                          classes={{
                            root: classes.root,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="No permitir respuestas después de la expiración"
                      className={classes.justifyContent}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12}>
                  <FormControl
                    component="fieldset"
                    className={classes.marginTop}
                  >
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue={values.solutionType.toString()}
                      onChange={(event) => {
                        setQuestionEngineType(parseInt(event.target.value));
                        setFieldValue(
                          "solutionType",
                          parseInt(event.target.value)
                        );
                      }}
                    >
                      <FormControlLabel
                        value="1"
                        className={classes.textColor}
                        control={
                          <Radio
                            color="default"
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={"Selecciona un motor de preguntas para resolver la requisición"}
                      />
                      <FormControlLabel
                        value="2"
                        className={classes.textColor}
                        control={
                          <Radio
                            color="default"
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={"Crear un motor de preguntas para resolver la requisición"}
                      />
                    </RadioGroup>
                  </FormControl>
                  {questionEngineTypeSolution()}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Card className={classes.heightImages}>
                <ElementsSelectTable
                  data={elemets}
                  setFieldValue={setFieldValue}
                  elementsIds={values.elementsIds}
                  disabled={disabled}
                />
              </Card>
            </GridItem>
            <GridItem xs={12}>
              <Card>
                <CardBody className={classes.heightImages}>
                  <Field
                    name={"images"}
                    render={({ field }) => {
                      return (
                        <ImageUploading
                          multiple
                          value={field.value}
                          onChange={(imageList, addUpdateIndex) => {
                            // data for submit
                            setFieldValue("images", imageList);
                          }}
                          dataURLKey="url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <GridContainer>
                              <GridItem xs={12}>
                                <GridContainer>
                                  <GridItem xs={6}>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      style={
                                        isDragging
                                          ? { color: "red" }
                                          : {
                                              backgroundColor: "#6ab3d4",
                                              color: "#fff",
                                            }
                                      }
                                      onClick={onImageUpload}
                                      {...dragProps}
                                    >
                                      Subir Imagenes
                                    </Button>
                                  </GridItem>
                                  <GridItem xs={6}>
                                    <Button
                                      color="secondary"
                                      size="small"
                                      variant="contained"
                                      onClick={onImageRemoveAll}
                                    >
                                      Remover Todas
                                    </Button>
                                  </GridItem>
                                </GridContainer>
                              </GridItem>

                              <GridItem xs={12}>
                                <div className={classes.imageItemBtnWrapper}>
                                  {imageList.map((image, index) => (
                                    <div
                                      key={index}
                                      className={classes.imageItem}
                                    >
                                      <img src={image.url} alt="" width="150" />
                                      <div
                                        className={classes.imageItemBtnWrapper}
                                      >
                                        <IconButton
                                          onClick={() => onImageUpdate(index)}
                                          style={{ color: "#6ab3d4" }}
                                          aria-label="Actualizar"
                                          component="span"
                                        >
                                          <CachedIcon />
                                        </IconButton>

                                        <IconButton
                                          onClick={() => onImageRemove(index)}
                                          style={{ color: "red" }}
                                          aria-label="Eliminar"
                                          component="span"
                                        >
                                          <CancelIcon />
                                        </IconButton>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </GridItem>
                            </GridContainer>
                          )}
                        </ImageUploading>
                      );
                    }}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center" className={classes.marginTop}>
        <GridItem>
          <ButtonIcon
            onClick={() => history.goBack()}
            icon={Cancel}
            color={"primary"}
            label={"Cancelar"}
          />
        </GridItem>
        <GridItem>
          <ButtonIcon
            disabled={!isValid}
            icon={CheckCircleIcon}
            label={"Guardar"}
            color={"info"}
            block={true}
            onClick={() => {
              submitForm();
            }}
          />
        </GridItem>
      </GridContainer>
    </Form>
  );
};

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: "15px",
  },
  textColor: {
    color: "#000",
  },
  error: {
    backgroundColor: "red",
  },
  radio: {
    "&$checked": {
      color: "#6ab3d4",
    },
  },
  checked: {},
  heightImages: {
    maxHeight: '350px',
    overflowY: 'scroll'
  },
  imageItem: {
    display: 'flex',
    margin: '15px 0',
  },
  imageItemBtnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    alignItems: 'center'
  },
  selectButtons: {
    display: 'flex',
    marginLeft: '10px',
  }
}));

const RequisitionFormFK = withFormik({
  validationSchema: RequisitionSchema,
  validateOnChange: true,
  mapPropsToValues: ({ requisition }) => {
console.log(12144343, requisition)
    return {
      name: requisition?.name ?? "",
      expirationDate: requisition?.expirationDate ?? moment().format(""),
      areaName: requisition?.areaName ?? "",
      description: requisition?.description ?? "",
      solutionType: requisition?.solutionModuleId ? 1: "",
      elementsIds: requisition?.elementsIds ?? [],
      images: requisition?.images?.length ? requisition?.images?.map(image => ({url: image.original})) : [],
      questionEngineName: '',
      solutionModuleId: requisition?.solutionModuleId ?? "",
      expiredSwitch: requisition?.expiredSwitch,
    };
  },
  handleSubmit: (values, { props }) => {
    console.log("submitting", values);
    props.submitSuccess(values);
  },
})(RequisitionForm);

export default RequisitionFormFK;
