import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Select from 'react-select';
import withStyles from "@material-ui/core/styles/withStyles";
import history from "utils/history";
import CardHeaderSimple from "components/Header/CardHeaderSimple"
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";

function VisitSelect({ classes, visitsIDs, selectedVisitID, setVisitID }) {
  const navigateSelectedVisit = React.useCallback((item) => {
    history.push(`/visits/${item.value}/dashboard/`)
    setVisitID(item);
  }, [setVisitID]);

  const selectOptions = visitsIDs.map(visit => ({
    value: visit.id,
    label: visit.name
  }));

  return (
    <Card className={classes.cardLessBorder}>
      <CardHeaderSimple title={"Selecciona la visita a analizar"} />          
      {/* <CardHeader color="info" text>
        <GridContainer>
          <GridItem>
            <CardText color="info">
              <h4>Selecciona la visita a analizar</h4>
            </CardText>
          </GridItem>
        </GridContainer>
      </CardHeader> */}
      <CardBody className={classes.cardSelector}>
        <div className={classes.selectors}>
          <Select
            className={classes.selector}
            placeholder={"Seleccionar una visita"}
            value={selectedVisitID}
            onChange={navigateSelectedVisit}
            options={selectOptions}
          />
        </div>
      </CardBody>
    </Card>
  );
}

VisitSelect.propTypes = {
  visitsIDs: PropTypes.array, 
  selectedVisitID: PropTypes.object,
  setVisitID: PropTypes.func
};

const styles = theme => ({
  cardSelector: {
    zIndex: 11
  },
  cardLessBorder: {
    marginBottom: "0px"
  },
  selector: {
    width: "100%"
  },
  selectors:{
    display: "flex"
  }
});

export default withStyles(styles)(VisitSelect);
